export const configuration = {
    header: {
    heading: 'INFO PANEL',
    headingtext: 'Namari is a free landing page template you can use for your projects. It is free to use for your personal and commercial projects, enjoy!',
    buttontext: 'Do some action!!',
    buttonlink: '\home'
},
    intro: {
        tagline: 'SUCCESS',
        title: 'How We Help You To Sell Your Product?',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do \n eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minimveniam!',
        features: [
            {icon: 'html5', title: 'HTML5 &amp; CCS3', description: 'Has ne tritani atomorum conclusionemque, in dolorum volumus cotidieque eum. At vis choro'},
            {icon: 'bolt', title: 'Easy to use', description: 'Cu vero ipsum vim, doctus facilisi sea in. Eam ex falli honestatis repudiandae, sit detracto mediocrem'},
            {icon: 'tablet', title: 'Fully responsive', description: 'Id porro tritani recusabo usu, eum intellegam consequuntur et. Fugit debet ea sit, an pro'},
            {icon: 'rocket', title: 'Parallax effect', description: 'Id porro tritani recusabo usu, eum intellegam consequuntur et. Fugit debet ea sit, an pro'},
        ]
    },
services: {
    tagline: 'BELIEVING',
    title: 'Focusing On What Matters Most',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
},
testimonials: {
    tagline: 'FEEDBACK',
    title: 'What our customers are saying?',
    description: '',
    feedbacks : [
        {name: 'John Doe', userimage: 'user-1.jpg',comments: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',company: 'Happy Customer'},
        {name: 'Roslyn Doe', userimage: 'user-2.jpg',comments: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua', company: 'Happy Customer'},
        {name: 'Thomas Doe', userimage: 'user-3.jpg',comments: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        company: 'Happy Customer'},
    ]
},
clients: {
    tagline: 'TRUST',
    title: 'Companies who use our services',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam!',
    companies: [
        {name: 'Tree', weblink: 'company-logo1.png', logo: 'company-logo1.png'},
        {name: 'Fingerprint', weblink:'company-logo2.png', logo:'company-logo2.png'},
        {name: 'The Man', weblink: 'company-logo3.png', logo:'company-logo3.png'},
        {name: 'Mustache', weblink: 'company-logo4.png', logo:'company-logo4.png'},
        {name: 'Goat', weblink: 'company-logo5.png', logo: 'company-logo5.png'},
        {name: 'Justice', weblink: 'company-logo6.png', logo:'company-logo6.png'},
        {name: 'Ball', weblink: 'company-logo7.png', logo: 'company-logo7.png'},
        {name: 'Cold', weblink: 'company-logo8.png', logo: 'company-logo8.png'},
        {name: 'Cold', weblink: 'company-logo9.png', logo: 'company-logo9.png'},
    ]
},
pricing: {
    tagline: 'YOUR CHOICE',
    title: 'We have the right package for you',
    description: '',
    plans: [
        {title: 'SMALL', subtitle: 'The standard version', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', price: '40/m', currency: '€',
        displays: '1 displays', support:'2h support', tutorials: 'Video tutorials', email: 'Email - Support', update : '1 year free updates',
        buttontext: 'Buy now', buttonlink: '#', featured: true
    },
    {title: 'MEDIUM', subtitle: 'Most popular choice', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', price: '140/m', currency: '€',
    displays: '5 Displays', support: '2h support', tutorials: 'Video tutorials', email: 'Email - Support', update: '1 year free updates', buttontext:'Buy now',
    buttonlink: '#', featured: true
},
{title: 'LARGE', subtitle: 'For the BIG guys', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', price: '250/m', currency: '€',
displays: '10 Displays', support: '10h support', tutorials: 'Video tutorials', email: 'Email - Support', update: 'Lifetime free updates',
buttontext: 'Buy now', buttonlink: '#', featured: true
}
]
},
gallery: {
    images: [
        'gallery-image-1.jpg',
        'gallery-image-2.jpg',
        'gallery-image-3.jpg',
        'gallery-image-4.jpg',
        'gallery-image-5.jpg',
        'gallery-image-6.jpg'
    ]
},
footer: {
    copyrighttext: 'PC PRO d.o.o.',
    developer: 'Bekim Lutolli',
    developerlink: 'https://pc-pro.si'
},
socialsites: [
    {link: 'https://www.facebook.com/', title: 'Facebook', target: '_blank', username: 'PCPRO', icon: 'facebook' },
    {link: 'https://plus.google.com/', title: 'Google+', target: '_blank', username: 'PCPRO', icon: 'google-plus' },
    {link: 'https://twitter.com/', title: 'Twitter', target: '_blank', username: 'PCPRO', icon: 'twitter' },
    {link: 'https://www.instagram.com/', title: 'Instagram', target: '_blank', username: 'PCPRO', icon: 'instagram' },
    {link: 'https://vk.com/onbehancer', title: 'Behancer', target: '_blank', username: 'PCPRO', icon: 'behance' },
],

blog: {
    tagline: 'OUR NEWS',
    title: 'Our little world :)',
    posts:[
        {id: 1, title: 'The first Article', author:'AD', image: 'gallery-image-1.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 2, title: 'The seccond Article', author:'AD', image: 'gallery-image-2.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 3, title: 'The third Article', author:'AD', image: 'gallery-image-3.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 4, title: 'The forth Article', author:'AD', image: 'gallery-image-4.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 5, title: 'The fifth Article', author:'AD', image: 'gallery-image-5.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 6, title: 'The sixth Article', author:'AD', image: 'gallery-image-6.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 7, title: 'The seventh Article', author:'AD', image: 'gallery-image-1.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 8, title: 'The eith Article', author:'AD', image: 'gallery-image-2.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 9, title: 'The thenth Article', author:'AD', image: 'gallery-image-3.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
        {id: 10, title: 'The eleventh Article', author:'AD', image: 'gallery-image-4.jpg', publishdate: '2015-03-25T12:00:00Z', excerpt: 'This is the summary of Article...'},
    ]

}

};