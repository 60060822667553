import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../config.service';
import { NgModule }         from '@angular/core';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  intro = {};


  constructor(private config: ConfigService) { }

  ngOnInit() {
    this.intro = this.getIntro();
  }
  getIntro(){
    return this.config.getConfig().intro;
  }
}