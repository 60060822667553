import { Component } from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'My website';
}
