import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../config.service';
import { NgModule }         from '@angular/core';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  gallery = {};


  constructor(private config: ConfigService) { }

  ngOnInit() {
    this.gallery = this.getGallery();
  }
  getGallery(){
    return this.config.getConfig().gallery;
  }
}